import { FieldMergeFunction } from '@apollo/client/cache/inmemory/policies';
import type {
  FieldPolicy,
  FieldReadFunction,
  KeyFieldsFunction,
} from '@apollo/client/cache/inmemory/policies';
import type { Mutation, Query } from '../schema.graphql';
import type { GqlTypeMap } from '../typeMap';
import { handleDelete } from './deletes/handleDelete';
import { redirectToTypeById } from './id-lookup/field-policy';
import { pageLimitPagination } from './lists/page-limit-pagination';
import { optional, Parsers } from './scalars/scalars.parser';

type FieldPolicies<T> = {
  [K in keyof T]?: FieldPolicy<T[K]> | FieldReadFunction<T[K]>;
};

type KeySpecifier<K = string> = ReadonlyArray<K | KeySpecifier>;

type GqlTypeMapAndQueries = GqlTypeMap & { Query: Query; Mutation: Mutation };

export interface TypePolicy<T> {
  keyFields?: KeySpecifier<keyof T> | KeyFieldsFunction | false;
  merge?: FieldMergeFunction<T> | boolean;
  queryType?: true;
  mutationType?: true;
  subscriptionType?: true;
  fields?: FieldPolicies<T>;
}

type TypePolicies = {
  [K in keyof GqlTypeMapAndQueries]?: TypePolicy<GqlTypeMapAndQueries[K]>;
};

export const typePolicies: TypePolicies = {
  DateRange: {
    keyFields: false,
    merge: true,
    fields: {
      end: {
        read: optional(Parsers.Date),
      },
      start: {
        read: optional(Parsers.Date),
      },
    },
  },
  Language: {
    fields: {
      ethnologue: { merge: true },
      createdAt: {
        read: Parsers.DateTime,
      },
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      engagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      locations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      posts: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      projects: pageLimitPagination({ sort: 'name', order: 'ASC' }),
    },
  },
  EthnologueLanguage: { keyFields: false },
  IanaCountry: { keyFields: ['code'] },
  TimeZone: { keyFields: ['name'] },
  IsoCountry: { keyFields: ['alpha3'] },
  Secured: {
    keyFields: false,
    merge: true,
  },
  FileNode: {
    fields: {
      parents: {
        merge: false,
      },
    },
  },
  ChangesetAware: {
    // Include changeset ID in ChangesetAware's key fields if it is given
    keyFields: (object: any) =>
      object?.changeset?.id ? ['id', 'changeset', ['id']] : ['id'],
  },
  ChangesetDiff: {
    merge: true,
  },
  ProductProgress: {
    keyFields: ['product', ['id'], 'report', ['id'], 'variant', ['key']],
  },
  LanguageEngagement: {
    fields: {
      partnershipsProducingMediums: {
        // Entire updated list is returned each time.
        merge: false,
      },
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      products: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      progressReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
    },
  },
  ProgressReport: {
    fields: {
      varianceExplanation: {
        merge: true,
      },
      createdAt: {
        read: Parsers.DateTime,
      },
      due: {
        read: Parsers.Date,
      },
      end: {
        read: Parsers.Date,
      },
      start: {
        read: Parsers.Date,
      },
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      communityStories: pageLimitPagination(),
      highlights: pageLimitPagination(),
      media: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      teamNews: pageLimitPagination(),
    },
  },
  Partner: {
    fields: {
      projects: {}, // no page merging (infinite scroll)
      engagements: {},
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      languages: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      posts: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
    },
  },
  Query: {
    fields: {
      projects: {},
      engagements: {},
      progressReports: {},
      unstable_stubDate: {
        read: optional(Parsers.Date),
      },
      unstable_stubDateTime: {
        read: optional(Parsers.DateTime),
      },
      unstable_stubInlineMarkdown: {
        read: optional(Parsers.InlineMarkdown),
      },
      unstable_stubRichText: {
        read: optional(Parsers.RichText),
      },
      unstable_stubURL: {
        read: optional(Parsers.URL),
      },
      unstable_stubUpload: {
        read: optional(Parsers.Upload),
      },
      ceremonies: pageLimitPagination({ sort: 'projectName', order: 'ASC' }),
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      educations: pageLimitPagination({ sort: 'institution', order: 'ASC' }),
      ethnoArts: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      fieldRegions: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      fieldZones: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      films: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      fundingAccounts: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      internshipProjects: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      languageEngagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      languages: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      locations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      notifications: pageLimitPagination(),
      organizations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      partners: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      partnerships: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      periodicReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      products: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      projectMembers: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      stories: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      suggestProductCompletionDescriptions: pageLimitPagination(),
      translationProjects: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      unavailabilities: pageLimitPagination({ sort: 'start', order: 'DESC' }),
      users: pageLimitPagination({ sort: 'id', order: 'ASC' }),
      ceremony: redirectToTypeById('Ceremony'),
      changeset: redirectToTypeById('Changeset'),
      commentThread: redirectToTypeById('CommentThread'),
      directory: redirectToTypeById('Directory'),
      education: redirectToTypeById('Education'),
      engagement: redirectToTypeById('Engagement'),
      ethnoArt: redirectToTypeById('EthnoArt'),
      fieldRegion: redirectToTypeById('FieldRegion'),
      fieldZone: redirectToTypeById('FieldZone'),
      file: redirectToTypeById('File'),
      fileNode: redirectToTypeById('FileNode'),
      film: redirectToTypeById('Film'),
      fundingAccount: redirectToTypeById('FundingAccount'),
      language: redirectToTypeById('Language'),
      languageEngagement: redirectToTypeById('LanguageEngagement'),
      location: redirectToTypeById('Location'),
      organization: redirectToTypeById('Organization'),
      partner: redirectToTypeById('Partner'),
      partnership: redirectToTypeById('Partnership'),
      periodicReport: redirectToTypeById('PeriodicReport'),
      post: redirectToTypeById('Post'),
      product: redirectToTypeById('Product'),
      project: redirectToTypeById('Project'),
      projectMember: redirectToTypeById('ProjectMember'),
      story: redirectToTypeById('Story'),
      unavailability: redirectToTypeById('Unavailability'),
      user: redirectToTypeById('User'),
    },
  },
  Audio: {
    fields: {
      url: {
        read: Parsers.URL,
      },
    },
  },
  Budget: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  BudgetRecord: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Ceremony: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Comment: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
    },
  },
  CommentThread: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      comments: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
    },
  },
  DerivativeScriptureProduct: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  DirectScriptureProduct: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Directory: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      children: pageLimitPagination({ sort: 'name', order: 'ASC' }),
    },
  },
  Education: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  EthnoArt: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  FieldRegion: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  FieldZone: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  File: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      url: {
        read: Parsers.URL,
      },
      children: pageLimitPagination({ sort: 'name', order: 'ASC' }),
    },
  },
  FileVersion: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      url: {
        read: Parsers.URL,
      },
    },
  },
  Film: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  FinancialReport: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      due: {
        read: Parsers.Date,
      },
      end: {
        read: Parsers.Date,
      },
      start: {
        read: Parsers.Date,
      },
    },
  },
  FundingAccount: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Image: {
    fields: {
      url: {
        read: Parsers.URL,
      },
    },
  },
  InternshipEngagement: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
    },
  },
  InternshipProject: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      changeRequests: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      engagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      financialReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      internshipEngagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      narrativeReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      otherLocations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      partnerships: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      posts: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      team: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
    },
  },
  Location: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  MomentumTranslationProject: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      changeRequests: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      engagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      financialReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      languageEngagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      narrativeReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      otherLocations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      partnerships: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      posts: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      team: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
    },
  },
  MultiplicationTranslationProject: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
      changeRequests: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      engagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      financialReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      languageEngagements: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      narrativeReports: pageLimitPagination({ sort: 'start', order: 'ASC' }),
      otherLocations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      partnerships: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      posts: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      team: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
    },
  },
  NarrativeReport: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      due: {
        read: Parsers.Date,
      },
      end: {
        read: Parsers.Date,
      },
      start: {
        read: Parsers.Date,
      },
    },
  },
  Organization: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      locations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
    },
  },
  OtherProduct: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Partnership: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  PnpProblem: {
    fields: {
      message: {
        read: Parsers.InlineMarkdown,
      },
    },
  },
  Post: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
    },
  },
  ProgressReportMedia: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  ProgressReportWorkflowEvent: {
    fields: {
      at: {
        read: Parsers.DateTime,
      },
    },
  },
  ProjectChangeRequest: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  ProjectMember: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
    },
  },
  ProjectWorkflowEvent: {
    fields: {
      at: {
        read: Parsers.DateTime,
      },
    },
  },
  Prompt: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  PromptResponse: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  PromptVariantResponse: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      modifiedAt: {
        read: Parsers.DateTime,
      },
    },
  },
  SecuredDate: {
    fields: {
      value: {
        read: optional(Parsers.Date),
      },
    },
  },
  SecuredDateNullable: {
    fields: {
      value: {
        read: optional(Parsers.Date),
      },
    },
  },
  SecuredDateTime: {
    fields: {
      value: {
        read: optional(Parsers.DateTime),
      },
    },
  },
  SecuredDateTimeNullable: {
    fields: {
      value: {
        read: optional(Parsers.DateTime),
      },
    },
  },
  SecuredRichText: {
    fields: {
      value: {
        read: optional(Parsers.RichText),
      },
    },
  },
  SecuredRichTextNullable: {
    fields: {
      value: {
        read: optional(Parsers.RichText),
      },
    },
  },
  SimpleTextNotification: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Story: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  Unavailability: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
    },
  },
  User: {
    fields: {
      createdAt: {
        read: Parsers.DateTime,
      },
      commentThreads: pageLimitPagination({ sort: 'createdAt', order: 'DESC' }),
      education: pageLimitPagination({ sort: 'institution', order: 'ASC' }),
      locations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      organizations: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      partners: pageLimitPagination({ sort: 'createdAt', order: 'ASC' }),
      projects: pageLimitPagination({ sort: 'name', order: 'ASC' }),
      unavailabilities: pageLimitPagination({ sort: 'start', order: 'DESC' }),
    },
  },
  VariantResponse: {
    fields: {
      modifiedAt: {
        read: optional(Parsers.DateTime),
      },
    },
  },
  Video: {
    fields: {
      url: {
        read: Parsers.URL,
      },
    },
  },
  Mutation: {
    fields: {
      deleteComment: handleDelete('Comment'),
      deleteEducation: handleDelete('Education'),
      deleteEngagement: handleDelete('Engagement'),
      deleteEthnoArt: handleDelete('EthnoArt'),
      deleteFieldRegion: handleDelete('FieldRegion'),
      deleteFieldZone: handleDelete('FieldZone'),
      deleteFileNode: handleDelete('FileNode'),
      deleteFilm: handleDelete('Film'),
      deleteFundingAccount: handleDelete('FundingAccount'),
      deleteLanguage: handleDelete('Language'),
      deleteLocation: handleDelete('Location'),
      deleteOrganization: handleDelete('Organization'),
      deletePartner: handleDelete('Partner'),
      deletePartnership: handleDelete('Partnership'),
      deletePost: handleDelete('Post'),
      deleteProduct: handleDelete('Product'),
      deleteProgressReportMedia: handleDelete('ProgressReportMedia'),
      deleteProject: handleDelete('Project'),
      deleteProjectChangeRequest: handleDelete('ProjectChangeRequest'),
      deleteProjectMember: handleDelete('ProjectMember'),
      deleteStory: handleDelete('Story'),
      deleteUnavailability: handleDelete('Unavailability'),
      deleteUser: handleDelete('User'),
    },
  },
};
